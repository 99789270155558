import { ref } from 'vue'
import { SupportedImagesTypes, SupportedImageFileSizeLimit, SampleResponseRegEx, EscapeChar } from '../utilities/constants'

export function useValidateFields() {
  const validHostnameRegExp = ref(/^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?)*$/i)

  function validateUrl(urlString) {
    const regxUrl = /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/
    return regxUrl.test(urlString)
  }

  function validateHostname(hostnameString) {
    if (!validHostnameRegExp.value.test(hostnameString)) {
      return false
    }
    return true
  }

  function getAcceptedFormat() {
    let acceptedFileTypes = ''
    SupportedImagesTypes.forEach((type) => {
      acceptedFileTypes += `.${type},`
    })
    return acceptedFileTypes
  }

  function fileTypeExist(type, supportType) {
    if (supportType === 'svg') {
      return type === 'svg'
    } else {
      return SupportedImagesTypes.indexOf(type) > -1
    }
  }

  function fileSizeLimit(size) {
    return !(size > SupportedImageFileSizeLimit)
  }

  function addEscapeCharacter(value) {
    return value
      ? value.toString().replace(SampleResponseRegEx, (char) => {
          if (char === '\\') {
            char = '\\\\'
          }
          return EscapeChar + char + EscapeChar || char
        })
      : value
  }

  function scrollToErrorMessage() {
    this.$nextTick(() => {
      const element = document.querySelectorAll('.d-block.invalid-feedback')
      const elementParent = element.length ? element[0].parentElement : false
      if (elementParent) {
        element[0].parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    })
  }

  return { validateUrl, validateHostname, getAcceptedFormat, fileTypeExist, fileSizeLimit, addEscapeCharacter, scrollToErrorMessage }
}
